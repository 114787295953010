import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { InventoryItem } from '../types/inventory';

interface InventoryItemRowProps {
  item: InventoryItem;
}

const InventoryItemRow: React.FC<InventoryItemRowProps> = ({ item }) => {
  const formatDimensions = (item: InventoryItem): string => {
    const dimensions = [
      item.Length ? `L=${item.Length}` : null,
      item.Width ? `W=${item.Width}` : null,
      item.Height ? `H=${item.Height}` : null,
      item.Thickness ? `T=${item.Thickness}` : null,
      item.OuterDiameter ? `OD=${item.OuterDiameter}` : null,
      item.WallThickness ? `Wt=${item.WallThickness}` : null,
      item.WebThickness ? `Tw=${item.WebThickness}` : null,
      item.FlangeThickness ? `Tf=${item.FlangeThickness}` : null,
    ]
      .filter(Boolean)
      .join(', ');
    return dimensions || 'N/A';
  };

  return (
    <TableRow>
      <TableCell>{item.ProductNumber}</TableCell>
      <TableCell>{`${item.Form} ${item.Choice}`}</TableCell>
      <TableCell>{`${item.Grade} ${item.Surface}`}</TableCell>
      <TableCell>{item.Finish}</TableCell>
      <TableCell>{formatDimensions(item)}</TableCell>
      <TableCell>{item.Quantity}</TableCell>
      <TableCell>{item.Weight ? item.Weight * (item.Quantity || 1): 'N/A'}</TableCell>
      <TableCell>{item.Location}</TableCell>
    </TableRow>
  );
};

export default InventoryItemRow;
