import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import theme from './theme';
import Inventory from './components/Inventory';
import PreferencesMatching from './components/PreferencesMatching';
import ErrorBoundary from './components/ErrorBoundary';
import './App.css';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary>
        <Router>
          <div className="App">
            <AppBar position="static">
              <Toolbar>
                <Typography variant="h6" style={{ flexGrow: 1 }}>
                  Inventory App
                </Typography>
                <Button color="inherit" component={Link} to="/">
                  Inventory
                </Button>
                <Button color="inherit" component={Link} to="/preferences">
                  Preferences
                </Button>
              </Toolbar>
            </AppBar>
            <Routes>
              <Route path="/" element={<ErrorBoundary><Inventory /></ErrorBoundary>} />
              <Route path="/preferences" element={<ErrorBoundary><PreferencesMatching /></ErrorBoundary>} />
            </Routes>
          </div>
        </Router>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;