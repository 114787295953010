
import React from 'react';
import { Typography, Paper, Box, Table, TableBody, TableContainer, TableHead, TableRow, TableCell } from '@mui/material';
import InventoryItemRow from './InventoryItemRow';  // Import the InventoryItemRow component
import { InventoryItem } from '../types/inventory';  // Import the InventoryItem type

interface Preference {
  ID: number;
  Material: string;
  Form: string;
  Grade: string;
  Choice: string;
  WidthMin: number | null;
  WidthMax: number | null;
  ThicknessMin: number | null;
  ThicknessMax: number | null;
  UploadDate: string;
}

interface Match {
  Preference: Preference;
  Inventory: InventoryItem[];
}

interface MatchResultsProps {
  matches: Match[];
}

const MatchResults: React.FC<MatchResultsProps> = ({ matches }) => {
  console.log('Matches received:', matches);

  if (!matches || matches.length === 0) {
    return <Typography>No matches found.</Typography>;
  }

  return (
    <div>
      {matches.map((match, index) => (
        <Paper key={index} elevation={3} style={{ margin: '20px 0', padding: '20px' }}>
          <Typography variant="h6" gutterBottom>Match {index + 1}</Typography>

          {/* Display preference information */}
          <Box mb={2}>
            <Typography variant="subtitle1" gutterBottom>Preference</Typography>

            {/* Preference Table */}
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Material</TableCell>
                    <TableCell>Form</TableCell>
                    <TableCell>Grade</TableCell>
                    <TableCell>Choice</TableCell>
                    <TableCell>Width Min (mm)</TableCell>
                    <TableCell>Width Max (mm)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{match.Preference.Material}</TableCell>
                    <TableCell>{match.Preference.Form}</TableCell>
                    <TableCell>{match.Preference.Grade}</TableCell>
                    <TableCell>{match.Preference.Choice}</TableCell>
                    <TableCell>{match.Preference.WidthMin !== null ? match.Preference.WidthMin : 'N/A'}</TableCell>
                    <TableCell>{match.Preference.WidthMax !== null ? match.Preference.WidthMax : 'N/A'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {/* Display inventory items */}
          <Typography variant="subtitle1" gutterBottom>Inventory Items ({match.Inventory.length})</Typography>

          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Product Number</TableCell>
                  <TableCell>Form & Choice</TableCell>
                  <TableCell>Grade & Surface</TableCell>
                  <TableCell>Finish</TableCell>
                  <TableCell>Dimensions (mm)</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Total Weight (tonnes)</TableCell>
                  <TableCell>Location</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {match.Inventory.map((item) => (
                  <InventoryItemRow key={item.ID} item={item} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ))}
    </div>
  );
};

export default MatchResults;


