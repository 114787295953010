// PreferencesMatching.tsx

import React, { useState } from 'react';
import { Typography, Box } from '@mui/material';
import UploadPreferences from './UploadPreferences';
import MatchResults from './MatchResults';  // Add this line

const PreferencesMatching: React.FC = () => {
  const [matchResults, setMatchResults] = useState<any>(null);

  const handleMatch = (data: any) => {
    setMatchResults(data);
    console.log('Match results:', data);
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Preferences Matching</Typography>
      <UploadPreferences onMatch={handleMatch} />
      {matchResults && (
        <Box mt={4}>
          <Typography variant="h5" gutterBottom>Match Results</Typography>
          {/* Display match results here */}
          <MatchResults matches={matchResults} />
         </Box>
      )}
    </Box>
  );
};

export default PreferencesMatching;