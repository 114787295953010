import React, { useState, useEffect } from 'react';
import { 
  Typography, CircularProgress, Pagination, Box, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, Paper, useTheme, Select, MenuItem, FormControl, InputLabel, SelectChangeEvent
} from '@mui/material';
import { fetchInventory, fetchPane } from '../api/inventory';
import { InventoryItem } from '../types/inventory';
import InventoryItemRow from './InventoryItemRow'; // Import the new component

const Inventory: React.FC = () => {
  const [inventory, setInventory] = useState<InventoryItem[]>([]);
  const [totalWeight, setTotalWeight] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;
  const [sortBy, setSortBy] = useState<string>('form');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [inventoryData, paneData] = await Promise.all([
          fetchInventory(page, pageSize, sortBy, sortOrder),
          fetchPane()
        ]);
        setInventory(inventoryData.items);
        setTotalWeight(paneData.totalWeightInTonnes);
        setTotalPages(Math.ceil(inventoryData.total / pageSize));
        setError(null);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [page, pageSize, sortBy, sortOrder]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleSortByChange = (event: SelectChangeEvent) => {
    setSortBy(event.target.value as string);
    setPage(1);
  };

  const handleSortOrderChange = (event: SelectChangeEvent<'asc' | 'desc'>) => {
    setSortOrder(event.target.value as 'asc' | 'desc');
    setPage(1);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Inventory</Typography>
      <Typography variant="h6" gutterBottom>
        Total Weight: {totalWeight !== null ? `${totalWeight.toFixed(2)} tonnes` : 'N/A'}
      </Typography>
      
      <Box display="flex" justifyContent="space-between" mb={2}>
        <FormControl variant="outlined" style={{ minWidth: 120 }}>
          <InputLabel>Sort By</InputLabel>
          <Select value={sortBy} onChange={handleSortByChange} label="Sort By">
            <MenuItem value="weight">Weight</MenuItem>
            <MenuItem value="form">Form</MenuItem>
            <MenuItem value="choice">Choice</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" style={{ minWidth: 120 }}>
          <InputLabel>Order</InputLabel>
          <Select value={sortOrder} onChange={handleSortOrderChange} label="Order">
            <MenuItem value="asc">Ascending</MenuItem>
            <MenuItem value="desc">Descending</MenuItem>
          </Select>
        </FormControl>
      </Box>
      
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Product Number</TableCell>
              <TableCell>Form & Choice</TableCell>
              <TableCell>Grade & Surface</TableCell>
              <TableCell>Finish</TableCell>
              <TableCell>Dimensions (mm)</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Total Weight (tonnes)</TableCell>
              <TableCell>Location</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inventory.map((item) => (
              <InventoryItemRow key={item.ID} item={item} /> // Use the new component
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
      <Box display="flex" justifyContent="center" mt={4}>
        <Pagination 
          count={totalPages} 
          page={page} 
          onChange={handlePageChange} 
          color="primary" 
        />
      </Box>
    </Box>
  );
};

export default Inventory;
