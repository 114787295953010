import { InventoryResponse, PaneResponse } from '../types/inventory';
import { getApiURL } from '../apiUtils';

export const fetchInventory = async (
  page: number, 
  pageSize: number, 
  sortBy: string, 
  sortOrder: 'asc' | 'desc'
): Promise<InventoryResponse> => {
  const url = getApiURL(`/inventory?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}`);
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return await response.json();
};

export const fetchPane = async (): Promise<PaneResponse> => {
  const response = await fetch(getApiURL('/inventory/pane'));
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return await response.json();
};